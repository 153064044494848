<template lang="">
    <div>
      <div style="height: 500px">
        <iframe class="excalidraw__playground" width="300px" height="500px" src="https://excalidraw.edvantalabs.com" frameborder="0"></iframe>
      </div>
    </div>
</template>
<script>
// import { Excalidraw } from '@excalidraw/excalidraw';

export default {
  name: 'Draw Widget',
  mounted() {
    // console.log('xvf', Excalidraw);
  },
};
</script>
<style lang="scss">
.excalidraw {
    &__container {
        width: 100%;
        height: 100%;
    }

    &__playground{
        width: 100%
    }
}
</style>
